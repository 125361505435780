import { useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";

import FormValidators from "@monortc/front/helpers/form-validators";
import { axiosInstance } from "@monortc/front/services/AxiosService";
import { SetNotification } from "@monortc/front/actions/notificationAction";
import store from "@monortc/front/store";
import { reportError } from "@monortc/front/services/ErrorService";
import TextFieldControlSimple from "@monortc/front/components/form-elements/TextFieldControlSimple";
import ButtonSimple from "@monortc/front/components/ButtonSimple";
import CheckboxControlSimple from "@monortc/front/components/form-elements/CheckboxControlSimple";
import LoginSocialButtons from "@/components/Auth/LoginSocialButtons";
import ApiPath from "@/constants/ApiPath";
import { AuthRoutes } from "@/constants/routes/auth-routes";

interface ISignupForm {
  email: string;
  password: string;
  company: string;
  acceptTerms: boolean;
  termsDate?: Date;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 368,
      width: "100%",
    },
    title: {
      margin: 0,

      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
      letterSpacing: "0.25px",
    },
    socialsButtonsContainer: {
      marginTop: 24,
      display: "flex",
      justifyContent: "center",
    },
    orText: {
      margin: "24px 0 0",

      lineHeight: 1.5,
      letterSpacing: "0.15px",
    },
    form: {
      marginTop: 24,
    },
    nthTextField: {
      marginTop: 16,
    },
    termsText: { margin: 0 },
    termsLink: {
      color: theme.palette.primary.main,
      textDecoration: "none",

      transition: "0.2s color ease",

      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    checkbox: {
      width: "100%",
      margin: "9px 12px 0",
    },
    signUpButton: {
      margin: "30px auto 0",
    },
    existingAccountText: {
      margin: "24px 0 0",

      fontSize: 14,
      letterSpacing: "0.15px",
    },
    loginLink: {
      display: "inline-block",
      marginLeft: "4px",

      color: theme.palette.primary.main,
      textDecoration: "none",

      transition: "0.2s color ease",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  })
);

const SignUpPage = (props: RouteComponentProps<{}>) => {
  const classes = useStyles();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleIsPasswordVisible = () => setIsPasswordVisible((currentState) => !currentState);

  const onSubmit = (values: ISignupForm): Promise<void | Partial<Record<keyof ISignupForm, string | undefined>>> => {
    const signUpValues = values;
    signUpValues.termsDate = new Date();

    return axiosInstance
      .post(ApiPath.api.signup, { ...signUpValues, assureAuth: true })
      .then(() => {
        props.history.push(`${AuthRoutes.SignUpConfirm}/${signUpValues.email}`);
      })
      .catch((err) => {
        const result: Partial<Record<keyof ISignupForm, string>> = {};

        if (err.response && err.response.data && err.response.data.message) {
          if (err.response.data.message === "This email address is already being used") {
            result.email = "The email address you entered is already in use.";
          } else {
            store.dispatch(SetNotification(err.response.data.message));
          }
        } else {
          reportError("Couldn't sign up", err);
        }

        return result;
      });
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        Create an account
      </Typography>

      <Box className={classes.socialsButtonsContainer}>
        <LoginSocialButtons isSignUp />
      </Box>

      <p className={classes.orText}>Or sign up with email</p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field
              component={TextFieldControlSimple}
              type="email"
              name="email"
              label="Email Address"
              validate={FormValidators.composeValidators(FormValidators.required(), FormValidators.isEmail)}
            />
            <Field
              component={TextFieldControlSimple}
              type={!isPasswordVisible ? "password" : "text"}
              name="password"
              label="Password"
              validate={FormValidators.composeValidators(
                FormValidators.required(),
                FormValidators.minValue(8, "Password must be at least 8 characters.")
              )}
              endAdornment={
                <IconButton
                  onClick={toggleIsPasswordVisible}
                  edge="end"
                  size="small"
                  aria-label="toggle password visibility"
                >
                  {!isPasswordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              }
              className={classes.nthTextField}
            />
            <Field
              component={TextFieldControlSimple}
              type="text"
              name="company"
              label="Company"
              validate={FormValidators.required()}
              className={classes.nthTextField}
            />
            <Field
              name="acceptTerms"
              component={CheckboxControlSimple}
              type="checkbox"
              label={
                <p className={classes.termsText}>
                  I agree to the{" "}
                  <a
                    target="_blank"
                    href="https://www.spearline.com/terms/"
                    rel="noopener noreferrer"
                    className={classes.termsLink}
                  >
                    Terms of Service
                  </a>
                </p>
              }
              validate={FormValidators.required(`To open an account on Agent Assure you
                                                                       must agree to our terms of service.`)}
              className={classes.checkbox}
            />

            <ButtonSimple type="submit" isLoading={submitting} fullWidth className={classes.signUpButton}>
              Create Account
            </ButtonSimple>

            <p className={classes.existingAccountText}>
              Already have an account?{" "}
              <Link className={classes.loginLink} to={AuthRoutes.SignIn}>
                Login here
              </Link>
            </p>
          </form>
        )}
      />
    </Box>
  );
};

export default withRouter(SignUpPage);
